import React from 'react'
import {IconProps} from './Icons'

/**
 * Add icons related to task types here
 */

export const IconTask = (props: IconProps) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props} fill="currentcolor">
    <path d="M24 41.43c-8.96 0-16.25-7.29-16.25-16.25S15.04 8.93 24 8.93s16.25 7.29 16.25 16.25S32.96 41.43 24 41.43zm0-31.5c-8.41 0-15.25 6.84-15.25 15.25S15.59 40.43 24 40.43s15.25-6.84 15.25-15.25S32.41 9.93 24 9.93zM33.315 7.458l.64-.768 8.22 6.849-.64.768zM5.827 13.529l8.219-6.851.64.768-8.218 6.851z" />
    <path d="M33.54 32.96L23.5 26.01V14.58h1v10.91l9.6 6.65z" />
  </svg>
)

export const IconOk = (props: IconProps) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props} fill="currentcolor">
    <path d="M23.99 41.64c-9.65 0-17.5-7.85-17.5-17.5s7.85-17.5 17.5-17.5 17.5 7.85 17.5 17.5-7.85 17.5-17.5 17.5zm0-34c-9.1 0-16.5 7.4-16.5 16.5s7.4 16.5 16.5 16.5 16.5-7.4 16.5-16.5-7.41-16.5-16.5-16.5z" />
    <path d="M21.24 31.13l-6.76-6.76.71-.71 6.05 6.05 12.47-12.46.71.71z" />
  </svg>
)

export const IconTaskList = (props: IconProps) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props} fill="currentcolor">
    <path d="M20.92 36H32v1H20.92zM20.92 26H32v1H20.92zM20.92 31H32v1H20.92z" />
    <path d="M36.5 41.5h-25v-35h25v35zm-24-1h23v-33h-23v33z" />
    <path d="M17.08 28.5c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm0-3c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zM17.08 33.5c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm0-3c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zM17.08 38.5c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm0-3c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1z" />
  </svg>
)

export const IconLocation = (props: IconProps) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props} fill="currentcolor">
    <path d="M24 41.39l-.4-.55c-.44-.6-10.82-14.77-10.82-23.17 0-6.19 5.03-11.22 11.22-11.22s11.22 5.03 11.22 11.22c0 8.4-10.38 22.57-10.82 23.17l-.4.55zm0-33.94c-5.64 0-10.22 4.59-10.22 10.22 0 7.16 8.28 19.27 10.22 22.01 1.94-2.74 10.22-14.85 10.22-22.01 0-5.63-4.58-10.22-10.22-10.22z" />
    <path d="M24 21.92c-2.34 0-4.25-1.91-4.25-4.25s1.91-4.25 4.25-4.25 4.25 1.91 4.25 4.25-1.91 4.25-4.25 4.25zm0-7.49c-1.79 0-3.25 1.46-3.25 3.25s1.46 3.25 3.25 3.25 3.25-1.46 3.25-3.25-1.46-3.25-3.25-3.25z" />
  </svg>
)

export const IconAlarmMessage = (props: IconProps) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props} fill="currentcolor">
    <path d="M41.68 41.5h-1.21c-4.47 0-7.87-1.25-9.98-2.35A17 17 0 0124 40.44c-9.36 0-16.97-7.61-16.97-16.97S14.64 6.5 24 6.5s16.97 7.61 16.97 16.97c0 4.38-1.66 8.52-4.67 11.69 1.35 2.2 3.04 4.01 4.52 5.49l.86.85zm-11.15-3.46l.21.11c1.81.97 4.69 2.11 8.5 2.31-1.37-1.43-2.82-3.12-4-5.13l-.19-.33.27-.27c3-3.01 4.65-7.01 4.65-11.26C39.97 14.66 32.81 7.5 24 7.5S8.03 14.66 8.03 23.47 15.19 39.44 24 39.44c2.18 0 4.31-.44 6.31-1.3l.22-.1z" />
    <path d="M23.5 12.69h1V26.5h-1zM23.5 30.75h1v3.19h-1z" />
  </svg>
)

export const IconMessage = (props: IconProps) => (
  <svg height="24px" viewBox="0 -960 960 960" width="24px" fill="currentcolor" {...props}>
    <path d="M269-428.5h263.5V-454H269v25.5Zm0-119h422V-573H269v25.5Zm0-119h422V-692H269v25.5Zm-132 479v-578.56q0-24.38 16.53-40.91 16.53-16.53 40.75-16.53h571.44q24.22 0 40.75 16.53T823-766.2v412.4q0 24.24-16.53 40.77-16.53 16.53-40.75 16.53H246l-109 109ZM235.5-322h530q12 0 22-10t10-22v-412q0-12-10-22t-22-10h-571q-12 0-22 10t-10 22v517l73-73Zm-73 0v-476 476Z" />
  </svg>
)

export const IconFridge = (props: IconProps) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props} fill="currentcolor">
    <path d="M36.49 17.64h-25v-8.5a2.5 2.5 0 012.5-2.5h20a2.5 2.5 0 012.5 2.5v8.5zm-24-1h23v-7.5c0-.83-.67-1.5-1.5-1.5h-20c-.83 0-1.5.67-1.5 1.5v7.5zM33.99 41.64h-20a2.5 2.5 0 01-2.5-2.5v-19.5h25v19.5a2.5 2.5 0 01-2.5 2.5zm-21.5-21v18.5c0 .83.67 1.5 1.5 1.5h20c.83 0 1.5-.67 1.5-1.5v-18.5h-23z" />
    <path d="M15.49 10.14h1v4h-1zM15.49 23.14h1v14h-1z" />
  </svg>
)

export const IconUsers = (props: IconProps) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props} fill="currentcolor">
    <path d="M30.61 28.61c-3.01 0-5.46-2.45-5.46-5.46s2.45-5.46 5.46-5.46 5.46 2.45 5.46 5.46-2.46 5.46-5.46 5.46zm0-9.91c-2.46 0-4.46 2-4.46 4.46s2 4.46 4.46 4.46 4.46-2 4.46-4.46c-.01-2.46-2.01-4.46-4.46-4.46zM41.55 41.56H19.66l.01-.51c.16-5.97 4.96-10.65 10.93-10.65s10.77 4.68 10.93 10.65l.02.51zm-20.85-1h19.81a9.876 9.876 0 00-9.9-9.16c-5.25 0-9.51 3.98-9.91 9.16z" />
    <path d="M23.13 22.94a5.439 5.439 0 01-4.5-5.36c0-3.01 2.45-5.46 5.46-5.46 2.43 0 4.53 1.58 5.21 3.93l-.96.28c-.56-1.92-2.27-3.21-4.26-3.21-2.46 0-4.46 2-4.46 4.46 0 2.16 1.55 4 3.68 4.38l-.17.98zM14.06 35.5l-1-.03c.15-5.72 4.75-10.39 10.47-10.62l.04 1c-5.2.21-9.37 4.45-9.51 9.65z" />
    <g>
      <path d="M16.51 17.37a5.439 5.439 0 01-4.5-5.36c0-3.01 2.45-5.46 5.46-5.46 2.39 0 4.54 1.62 5.22 3.93l-.96.28c-.56-1.92-2.27-3.21-4.26-3.21-2.46 0-4.46 2-4.46 4.46 0 2.16 1.55 4 3.68 4.38l-.18.98zM7.44 29.92l-1-.03c.15-5.72 4.75-10.39 10.47-10.62l.04 1c-5.2.22-9.37 4.46-9.51 9.65z" />
    </g>
  </svg>
)

export const IconProfile = (props: IconProps) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props} fill="currentcolor">
    <path d="M23.99 22.02c-4.27 0-7.74-3.47-7.74-7.74s3.47-7.74 7.74-7.74 7.74 3.47 7.74 7.74-3.48 7.74-7.74 7.74zm0-14.48c-3.72 0-6.74 3.02-6.74 6.74s3.02 6.74 6.74 6.74 6.74-3.02 6.74-6.74-3.03-6.74-6.74-6.74zM41.53 41.74H6.44l.01-.51c.26-9.58 7.96-17.08 17.53-17.08s17.28 7.5 17.53 17.08l.02.51zm-34.06-1H40.5c-.5-8.79-7.66-15.59-16.51-15.59S7.97 31.96 7.47 40.74z" />
  </svg>
)

export const IconDocument = (props: IconProps) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props} fill="currentcolor">
    <path d="M30.19 6.64h-18.7v35h25V12.93l-6.3-6.29zm.3 1.71l4.29 4.29h-4.29V8.35zm-18 32.29v-33h17v6h6v27h-23z" />
    <path d="M15.99 36.14h16v1h-16zM15.99 26.14h16v1h-16zM15.99 31.14h16v1h-16z" />
  </svg>
)

export const IconDocumentLinked = (props: IconProps) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props} fill="currentcolor">
    <path d="M30.19 6.64h-18.7v35h25V12.93l-6.3-6.29zm.3 1.71l4.29 4.29h-4.29V8.35zm-18 32.29v-33h17v6h6v27h-23z" />
    <path d="M23.74 36.04h-4.9a3.44 3.44 0 110-6.88h.7v1h-.7c-1.35 0-2.44 1.09-2.44 2.44s1.1 2.44 2.44 2.44h4.9c1.35 0 2.44-1.1 2.44-2.44s-1.1-2.44-2.44-2.44h-.7v-1h.7a3.44 3.44 0 110 6.88z" />
    <path d="M29.13 33.09h-.7v-1h.7c1.35 0 2.44-1.09 2.44-2.44s-1.1-2.44-2.44-2.44h-4.9c-1.35 0-2.44 1.1-2.44 2.44s1.1 2.44 2.44 2.44h.7v1h-.7a3.44 3.44 0 110-6.88h4.9c1.9 0 3.44 1.54 3.44 3.44.01 1.9-1.54 3.44-3.44 3.44z" />
  </svg>
)

export const IconFolder = (props: IconProps) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props} fill="currentcolor">
    <path d="M20.21 10.64l-4-4H6.5v35h35v-31H20.21zm-4.42-3l4 4H40.5v6.03l-33-.03v-10h8.29zm-8.29 33v-22l33 .03v21.97h-33z" />
  </svg>
)

export const IconFolderLinked = (props: IconProps) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props} fill="currentcolor">
    <path d="M23.74 34.31h-4.9a3.44 3.44 0 110-6.88h.7v1h-.7c-1.35 0-2.44 1.09-2.44 2.44s1.1 2.44 2.44 2.44h4.9c1.35 0 2.44-1.1 2.44-2.44s-1.1-2.44-2.44-2.44h-.7v-1h.7a3.44 3.44 0 110 6.88z" />
    <path d="M29.13 31.37h-.7v-1h.7c1.35 0 2.44-1.09 2.44-2.44s-1.1-2.44-2.44-2.44h-4.9c-1.35 0-2.44 1.1-2.44 2.44s1.1 2.44 2.44 2.44h.7v1h-.7a3.44 3.44 0 110-6.88h4.9c1.9 0 3.44 1.54 3.44 3.44.01 1.9-1.54 3.44-3.44 3.44z" />
    <path d="M20.21 10.64l-4-4H6.5v35h35v-31H20.21zm-4.42-3l4 4H40.5v6.03l-33-.03v-10h8.29zm-8.29 33v-22l33 .03v21.97h-33z" />
  </svg>
)

export const IconAlarm = (props: IconProps) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props} fill="currentcolor">
    <path d="M24 41.5c-9.65 0-17.5-7.85-17.5-17.5S14.35 6.5 24 6.5 41.5 14.35 41.5 24 33.65 41.5 24 41.5zm0-34C14.9 7.5 7.5 14.9 7.5 24S14.9 40.5 24 40.5 40.5 33.1 40.5 24 33.1 7.5 24 7.5z" />
    <path d="M23.5 14h1v13h-1zM23.5 31h1v3h-1z" />
  </svg>
)

export const IconAddItem = (props: IconProps) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props} fill="currentcolor">
    <path d="M24 41.5c-9.65 0-17.5-7.85-17.5-17.5S14.35 6.5 24 6.5 41.5 14.35 41.5 24 33.65 41.5 24 41.5zm0-34C14.9 7.5 7.5 14.9 7.5 24S14.9 40.5 24 40.5 40.5 33.1 40.5 24 33.1 7.5 24 7.5z" />
    <path d="M34 23.5h-9.5V14h-1v9.5H14v1h9.5V34h1v-9.5H34z" />
  </svg>
)

export const IconChain = (props: IconProps) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props} fill="currentcolor">
    <path d="M22.3 41.58h-5.93v-6.57h-3.93v6.57H6.5v-11.5l7.82-4.75 7.98 4.74v11.51zm-4.93-1h3.93v-9.93l-6.98-4.15-6.82 4.14v9.94h3.93v-6.57h5.93v6.57zM41.5 41.58h-5.93v-6.57h-3.93v6.57H25.7v-11.5l7.82-4.75 7.98 4.74v11.51zm-4.93-1h3.93v-9.93l-6.98-4.15-6.83 4.14v9.94h3.93v-6.57h5.93v6.57zM31.9 22.75h-5.93v-6.57h-3.93v6.57H16.1v-11.5l7.82-4.75 7.98 4.74v11.51zm-4.93-1h3.93v-9.93l-6.98-4.15-6.83 4.14v9.94h3.93v-6.57h5.93v6.57z" />
  </svg>
)

export const IconSettings = (props: IconProps) => (
  <svg viewBox="0 0 48 48" width="1em" height="1em" {...props} fill="currentcolor">
    <path d="M24 29.91c-3.26 0-5.91-2.65-5.91-5.91 0-3.26 2.65-5.91 5.91-5.91 3.26 0 5.91 2.65 5.91 5.91 0 3.26-2.65 5.91-5.91 5.91zm0-10.82c-2.71 0-4.91 2.2-4.91 4.91s2.2 4.91 4.91 4.91 4.91-2.2 4.91-4.91-2.2-4.91-4.91-4.91z" />
    <path d="M27.29 41.41H20.7l-.79-5.23c-1.61-.54-3.12-1.42-4.41-2.56l-4.94 1.94-3.29-5.7 4.14-3.3c-.17-.86-.26-1.72-.26-2.55 0-.83.09-1.69.26-2.55l-4.14-3.3 3.29-5.7 4.94 1.94c1.29-1.14 2.81-2.02 4.41-2.56l.79-5.23h6.59l.79 5.23c1.6.54 3.12 1.42 4.41 2.56l4.94-1.94 3.29 5.7-4.14 3.3c.17.86.26 1.72.26 2.55 0 .83-.09 1.69-.26 2.55l4.14 3.3-3.29 5.7-4.94-1.94a12.975 12.975 0 01-4.41 2.56l-.79 5.23zm-5.72-1h4.86l.75-5 .3-.09c1.67-.51 3.25-1.43 4.56-2.65l.23-.21L37 34.31l2.43-4.21-3.96-3.16.07-.31c.2-.89.3-1.78.3-2.64 0-.86-.1-1.75-.3-2.64l-.07-.31 3.96-3.16L37 13.69l-4.72 1.85-.23-.21a11.815 11.815 0 00-4.56-2.65l-.3-.09-.75-5h-4.86l-.75 5-.3.09c-1.67.51-3.25 1.43-4.56 2.65l-.23.21L11 13.69 8.57 17.9l3.96 3.16-.07.31c-.2.89-.31 1.78-.31 2.64 0 .86.1 1.75.31 2.64l.07.31-3.96 3.14L11 34.31l4.72-1.85.23.21c1.31 1.22 2.89 2.13 4.56 2.65l.3.09.76 5z" />
  </svg>
)

export const IconPower = (props: IconProps) => (
  <svg viewBox="0 0 64 64" width="1em" height="1em" {...props} fill="currentcolor">
    <path d="M31.9351 30.4626V12H33.0641V30.4626H31.9351Z" />
    <path d="M37.4818 17.3551C37.4815 17.355 37.4822 17.3552 37.4818 17.3551C37.8435 17.4649 38.2017 17.5866 38.5538 17.7193C42.1272 19.0657 45.1842 21.572 47.2461 24.8696C49.5114 28.4925 50.4239 32.8282 49.8167 37.0826C49.2094 41.3371 47.1229 45.2256 43.9394 48.0361C40.7558 50.8465 36.6883 52.3909 32.4815 52.3864C28.2748 52.3818 24.2104 50.8287 21.0327 48.0113C17.8549 45.194 15.7765 41.301 15.178 37.0452C14.5796 32.7895 15.5011 28.4558 17.7738 24.8378C19.8425 21.5446 22.9047 19.045 26.4809 17.7062C26.8336 17.5742 27.1913 17.4535 27.5536 17.3444L27.8884 18.5056C24.1263 19.6386 20.8894 22.1187 18.7705 25.4918C16.6516 28.865 15.7925 32.9053 16.3505 36.873C16.9084 40.8407 18.8462 44.4702 21.8088 47.0969C24.7715 49.7236 28.5607 51.1716 32.4828 51.1758C36.4048 51.1801 40.1971 49.7402 43.1651 47.1199C46.1332 44.4997 48.0784 40.8744 48.6446 36.9079C49.2108 32.9414 48.36 28.8992 46.2481 25.5215C44.1368 22.1448 40.9063 19.6581 37.148 18.5166C37.1469 18.5162 37.1458 18.5159 37.1447 18.5156L37.4818 17.3551Z" />
  </svg>
)
export const IconDownload = (props: IconProps) => (
  <svg height="48px" viewBox="0 -960 960 960" width="48px" fill="#5f6368">
    <path d="M480-337 338-479l25-24 99 98v-359h35v359l99-98 26 24-142 142ZM263-196q-27.5 0-47.25-19.75T196-263v-97h35v97q0 12 10 22t22 10h434q12 0 22-10t10-22v-97h35v97q0 27.77-19.75 47.39Q724.5-196 697-196H263Z" />
  </svg>
)
const defaultProps = {
  width: '48px',
  height: '48px',
  color: 'inherit'
}
IconTask.defaultProps = defaultProps
IconOk.defaultProps = defaultProps
IconFridge.defaultProps = defaultProps
IconTaskList.defaultProps = defaultProps
IconLocation.defaultProps = defaultProps
IconChain.defaultProps = defaultProps
IconAlarmMessage.defaultProps = defaultProps
IconUsers.defaultProps = defaultProps
IconProfile.defaultProps = defaultProps
IconDocument.defaultProps = defaultProps
IconDocumentLinked.defaultProps = defaultProps
IconFolder.defaultProps = defaultProps
IconFolderLinked.defaultProps = defaultProps
IconAlarm.defaultProps = defaultProps
IconAddItem.defaultProps = defaultProps
IconSettings.defaultProps = defaultProps
